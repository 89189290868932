export const pipe_fields_id = [
  "executor_respons_vel_pelo_controle", // Nome do Solicitante
  "e_mail_do_executor_respons_vel_pelo_controle", // E-mail do Solicitante
  "empresa_aplic_vel", // Empresa Aplicável
  "m_s_e_ano_de_compet_ncia", //Mês e Ano de Competência
  "copy_of_empresa_aplic_vel", // Área responsável pelo lançamento
  "natureza_do_lan_amento", // Natureza do Lançamento
  "descri_o_do_lan_amento", // Descrição do Lançamento
  "sele_o_de_nica_op_o_vertical", // Impacto no P&L ?
  "o_lan_amento_dever_ser_revertido_no_pr_ximo_m_s", //O lançamento deverá ser revertido no próximo mês?
  "copy_of_o_lan_amento_dever_ser_revertido_no_pr_ximo_m_s", //O lançamento é recorrente?
  "revers_o", //Reversão 
  "valor", //Valor
  "evid_ncia_do_controle_efetuado", //Anexo - Suporte do lançamento
  "copy_of_anexo_suporte_do_lan_amento", //Anexar o template de lançamento
  "observa_o", //Observação:
  "rea_solicitante",
  "data_da_revers_o"

]
