import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { interval, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AzureService } from './autenticacao/azure.service';
import { HttpClient } from '@angular/common/http';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TokenServiceService } from './services/token-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { ControleDeVersoesService } from './services/controle-de-versoes.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'portal-front';
  isUserLoggedIn: boolean = false;
  cont: number = 0;
  groups: any;

  private readonly _destroy = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private azureService: AzureService,
    private tokenService: TokenServiceService,
    private controleVersaoS: ControleDeVersoesService,
    private spinner: NgxSpinnerService,
    private controleVersao: ControleDeVersoesService,
    private userInfo: UserInfosService,
    private route: ActivatedRoute,
    private msalService: MsalService
  ) {
    console.log((environment.homolog || environment.production || environment.localhost) && localStorage.getItem('sso') != 'accenture'? "sso nutrien": "sso accenture");
    this.getAccounts();

  }
  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete()
  }
  ngOnInit() {
    this.controleVersaoS.atualizarPortal();

    this.verificaTempoDeSessao()


    this.msalBroadcastService.inProgress$.pipe
      (filter((interactionStatus: InteractionStatus) =>
        interactionStatus == InteractionStatus.None),
        takeUntil(this._destroy))
      .subscribe(x => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        this.azureService.isUserLoggedIn.next(this.isUserLoggedIn);
      });

    console.log(environment.versao + " - " + environment.versaoData + " - " + environment.versaoHora);
    if (!!environment.dev) {
       this.popUp();
    }

    this.setUserEmail()
    this.setUserName()

    setTimeout(() => {
      this.consultaVersao()
    }, 1000);

    this.groups = this.route.data.subscribe(
      (groups) => {
        this.groups = groups.groups;
      }
    )
    if(!(environment.production || environment.homolog)) {
       this.popUp();
    }
  }


  teste(cont: number) {
    console.log(this.cont)
    this.cont = this.cont + 1
    return this.cont
  }


  login(): void {

    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
    } else {
      this.authService.loginRedirect()
    }
  }

  acessaIpe() {
    this.controleVersao.podeAcessarIpe().then((res) => {
      console.log(res)
    })
  }

  consultaVersao() {
    if (!!environment.localhost) {
      return;
    }
    this.controleVersao.consultaVersao().then((res) => {

      let resposta = res as any;
      resposta = resposta.data.dados.status

      if (resposta == "não existe o email no pipe!" || resposta == "não ok") {
        this.atualizaVersao()
         this.popUp();

      }
      // console.log("versao ok")
    })
  }

  atualizaVersao() {
    this.controleVersao.atualizaVersao().then((res) => {
      //
    })
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() != null
  }

  logout(): void {
    this.authService.logoutRedirect()
  }

  verificaTempoDeSessao() {
    if (environment.localhost || environment.dev) {
      return;
    }
    const time = 14400000
    const myTme = setTimeout(() => {
      return Swal.fire({
        title: 'Ops, sua sessão expirou!',
        text: 'Por favor, saia e logue novamente.',
        icon: 'error',
        confirmButtonText: 'Sair',
        confirmButtonColor: '#ff6961',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
        .then(() => {
          this.authService.logoutRedirect()
        }
        )
    }, time)
  }

  popUp() {
    Swal.fire({
      title: 'Comunicação de Melhoria',
      html: '<div style="text-align:justify; padding:20px">' +
      '<h4 style="text-align:center">Versão ' + environment.versao + ' - ' + environment.versaoData + '</h4>' +
      //   '<div>' +
      //   '<span style="color:#88D600">Atualizações automáticas a partir da versão 13.0.0</span><br>' +
      //   '<h6>Não se preocupe! Todas as atualizações deverão ocorrer em segundo plano de forma automática.</h6>' +
      //   '<span style="color:#88D600">Está com o portal desatualizado? Siga o passo a passo:</span></div>' +
      //'<img class="nbs_banner-img" src="../../../assets/img/melhoria.png" style= "width: 100%" >' +
       //'<h4 style="text-align:center">Outras atualizações</h4>' +
        'Finanças</br></br>'+
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão de item “IFRS 16” no campo Natureza do Lançamento.</span><br>' +
        //  '<p> - Aluguel: contratos, boletos, recibos, etc. (Não faz leitura automática)</p>'+
        //  '<p> - Ativo Fixo: Nota Fiscal de entrada de mercadoria adquirida pela empresa. Compra de Ativo Imobilizado. Inserir o número da AFE. </p>'+
        //  '<p> - Benefícios: Específico de Recursos Humanos para pagamento de benefícios relacionados ao colaborador: plano de saúde, ticket refeição, vale transporte, seguro de vida e previdência privada</p>'+
        //  '<p> - Cartório: Exclusivo para o pagamento de despesas e taxas cartorárias: autenticação de documentos, abertura e reconhecimento de firmas. (Não faz leitura automática)</p>'+
        //  '<p> - Contas de consumo: contas relativas à despesas de concessionárias de água, luz, telefone, internet, gás, etc.</p>'+
        //  '<p> - Correio: Fatura emitida pela Empresa Brasileira de Correios e Telégrafos</p>'+
        //  '<p> - Devolução cliente: Registro de negociação e autorização de pagamentos à clientes. (Não faz leitura automática)</p>'+
        //  '<p> - Escrituração não gera pagamento: Notas que não deverão gerar um pagamento, mas que deverão ser registradas no sistema para controle como por exemplo notas de amostras grátis</p>'+
        //  '<p> - Frete/CTE: Faturas e notas de frete com os respectivos conhecimentos de frete emitidos por transportadoras</p>'+
        //  '<p> - Guias/impostos/taxas: Pagamento de tributos e licenças de entidades públicas, sendo: federais, estaduais e municipais: IPI, ICMS, licença de funcionamento, taxa de bombeiro, IPTU e IPVA, contribuição para entidades de classe (CREA, CRA, CRC). (Não faz leitura automática)</p>'+
        //  '<p> - Importação: Compra de materiais e/ou equipamentos adquiridos fora do País ou para a contratação de prestadores de serviços internacionais. (Não faz leitura automática)</p>'+
        //  '<p> - Incentivo/Doação: Recibos e documentação de doações (Não faz leitura automática)</p>'+
        //  '<p> - Industrialização: insumos ou matérias-primas, produtos intermediários (semiacabados) ou material de embalagem</p>'+
        //  '<p> - Jurídico: Notas e recibos relativos ao departamento jurídico (Não faz leitura automática)</p>'+
        //  '<p> - Material (DANFE): Compras de qualquer mercadoria. Importante ressaltar que a DANFE (Documento Auxiliar da Nota Fiscal Eletrônica) é um documento utilizado para transportar o produto comprado. É de extrema importância anexar no CARD o arquivo XML</p>'+
        //  '<p> - Nota de crédito: documento de crédito emitido a cliente. (Não faz leitura automática)</p>'+
        //  '<p> - Remessa: Notas de remessa de mercadorias</p>'+
        //  '<p> - Seguro: Solicitações de Pagamentos de Apólices de Seguros Nutrien. </p>'+
        //  '<p> - Sem valor fiscal: recibos provisórios ou de transação de mercadoria. Este tipo de nota é exclusivo para o pagamento de fornecedor que não possuem obrigatoriedade de emitir nota fiscal</p>'+
        //  '<p> - Serviço: Notas relativas à prestação de serviços</p>'+

        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Solicitação de Lançamento de Notas</span><br><br>' +
        //  'Contabilidade</br></br>' +
        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          '<span style="color:#88D600">Inclusão de Campo Conta Contábil para tipo de nota "Cartório".</span><br>' +
          '<b style="font-weight: bold;">MENU: </b>' +
          '<span>Finanças/Lançamento/Pagamento(Notas/Imposto e outras cobranças)</span><br><br>' +
          // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          // '<span style="color:#88D600">Exclusão do campo “Número do bem”.</span><br>' +
          // '<b style="font-weight: bold;">MENU: </b>' +
          // '<span>Contabilidade/Imobilizado</span><br><br>' +
          // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          // '<span style="color:#88D600">Remoção da obrigatoriedade do campo “Número de Nota Fiscal”.</span><br>' +
          // '<b style="font-weight: bold;">MENU: </b>' +
          // '<span>Contabilidade/Imobilizado</span><br><br>' +
          // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          // '<span style="color:#88D600">Exclusão do campo “Formulário de autorização da contabilidade para emissão da nota fiscal está em anexo e assinado pela gestão?”.</span><br>' +
          // '<b style="font-weight: bold;">MENU: </b>' +
          // '<span>Contabilidade/Imobilizado</span><br><br>' +
          // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          // '<span style="color:#88D600">Atualização de nome do campo “Formulário Assinado” para “Formulário preenchido”.</span><br>' +
          // '<b style="font-weight: bold;">MENU: </b>' +
          // '<span>Contabilidade/Imobilizado</span><br><br>' +
          // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          // '<span style="color:#88D600">Inclusão de regra para o campo “Recebimento financeiro através de leilão?” ser exibido após o campo “Tipo de transação” for igual “Venda via Leilão”.</span><br>' +
          // '<b style="font-weight: bold;">MENU: </b>' +
          // '<span>Contabilidade/Imobilizado</span><br><br>' +
          // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          // '<span style="color:#88D600">Exclusão do item “Leilão” do campo “Tipo de transação”.</span><br>' +
          // '<b style="font-weight: bold;">MENU: </b>' +
          // '<span>Contabilidade/Imobilizado</span><br><br>' +
          // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
          // '<span style="color:#88D600">Inclusão dos itens “Doação” e “Sucata” do campo “Tipo de transação”.</span><br>' +
          // '<b style="font-weight: bold;">MENU: </b>' +
          // '<span>Contabilidade/Imobilizado</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão campo Ano Seguro com as opções: 2023;2024;2025;2026;2027;2028;2029. </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Finanças / Lançamento / Pagamento / Notas / Impostos e outras cobranças</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão do campo Formulário de solicitação de emissão de nota fiscal está anexado e preenchido? </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Contabilidade / Imobilizado.</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão de campo Formulário de autorização da contabilidade para emissão da nota fiscal está em anexo e assinado pela gestão? </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Contabilidade / Imobilizado.</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão de campo Incluir anexos. </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Contabilidade / Imobilizado.</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão de campo Número de nota fiscal. </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Contabilidade / Imobilizado.</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão de campo Recebimento financeiro através de leilão? </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Contabilidade / Imobilizado.</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão de campo Comprovante de pagamento. </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Contabilidade / Imobilizado.</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão de campo Valor do Faturamento (Valor total da venda dos itens). </span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Contabilidade / Imobilizado.</span><br><br>' +
        //  'Pagamentos</br></br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão da opção “Agrichem” no campo Empresa.</span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Serviços Financeiros / Finanças / Cartão de Crédito Corporativo - Serviços</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Para o Tipo de Nota de Crédito e Empresa Nutrien ou Agrichem, exibe o campo Conta Contábil.</span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Serviços Financeiros > Finanças > Lançamento/Pagamento - Notas/Impostos e outras cobranças</span><br><br>' +
         '</br>'+


        '</div><br>' +
        // '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        // '<span style="color:#88D600">: Inclusão da opção Recesso Estagiário no Menu Motivo da Exceção de Férias, no fluxo de Programar Férias < 40 Dias.</span><br>' +
        // '<b style="font-weight: bold;">MENU: </b>' +
        // '<span>myHR / Solicitações / Programar Férias < 40 Dias</span><br><br>' +
        '</div><br>' +
        '</div></div></div>',
      iconColor: '#88D600',
      confirmButtonColor: '#88D600',
      customClass: {
        container: "comunicadoMelhoria",
      }

    })

  }

  getInfos(): string | undefined {
    // console.log(this.authService.instance.getAllAccounts()[0])
    // console.log('name',this.authService.instance.getAllAccounts()[0]?.name)
    // console.log('username',this.authService.instance.getAllAccounts()[0]?.username)

    return this.authService.instance.getActiveAccount()?.name
  }

  setUserName(): any {
    this.userInfo.setName(this.authService.instance.getAllAccounts()[0]?.name)
  }

  setUserEmail(): any {
    this.userInfo.setUserEmail(this.authService.instance.getAllAccounts()[0]?.username)
  }

  getUserEmail() {
    return this.userInfo.getUserEmail()
  }

  verificaPerfil(group: any) {
    // console.log('perfil',this.userInfo.getUserGroups().includes(group))
    var perfil = this.userInfo.getUserGroups().includes(group)
    // console.log("group:", group, perfil)
    return perfil
  }

  getUserProfile() {
    this.userInfo.getUserGroupsFromAzure().subscribe((res: any) => {
      this.userInfo.setUserGroups(res)
    });
  }

  openForm() {
    let userEmail = this.userInfo.getUserEmail()
    let userName = this.userInfo.getName()

    userName = userName.replace(" ", "_").replace(",", "")

    const url = environment.sala_de_suporte + "?nome_do_solicitante=" + userName + "&e_mail_do_solicitante=" + userEmail
    window.open(url, "_blank")
  }
  async getAccounts(): Promise<any> {
    return await new Promise(resolve => {
      const checkValue = () => {
        if (!!this.msalService.instance.getAllAccounts()[0]?.name && !!this.msalService.instance.getAllAccounts()[0]?.username) {
          this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name);
          this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username);
          resolve(true);
        } else {
          setTimeout(checkValue, 50); // Espera 100ms
        }
      };
      checkValue();
    });
  }

}
