import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.interface';


@Component({
  selector: 'app-retificacao-obrigacoes',
  templateUrl: './retificacao-obrigacoes.component.html',
  styleUrls: ['./retificacao-obrigacoes.component.css']
})
export class RetificacaoObrigacoesComponent implements OnInit {

  fields: FormField[] = [];

  constructor() { }

  ngOnInit(): void {

    this.fields = [
      {
        "label": "Divisão fiscal",
        "name": "divis_o_fiscal",
        "select": [
          {
            "type": "option",
            "name": "Fiscal diretos",
            "value": "Fiscal diretos"
          },
          {
            "type": "option",
            "name": "Fiscal indiretos",
            "value": "Fiscal indiretos"
          },
          {
            "type": "option",
            "name": "Fiscal indiretos & Fiscal diretos",
            "value": "Fiscal indiretos & Fiscal diretos"
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Período da retificação ",
        "name": "per_odo_da_retifica_o",
        "select": [
          {
            "type": "option",
            "name": "Retificações antes de 07/2022",
            "value": "Retificações antes de 07/2022"
          },
          {
            "type": "option",
            "name": "Retificações após 07/2022-NBS",
            "value": "Retificações após 07/2022-NBS"
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Houveram retificações anteriores?",
        "name": "houve_retifica_es_anterior",
        "select": [
          {
            "type": "option",
            "name": "Sim",
            "value": "Sim",
            "fields": [
              {
                "label": "Detalhamento da retificação anterior",
                "name": "detalhamento_da_retifica_o_anterior",
                "type": "textArea",
                "validators": Validators.required
              },
              {
                "label": "Número do recibo da entrega Original ou retificada",
                "name": "n_mero_do_recibo_da_entrega_original_ou_retificada",
                "type": "text",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Não",
            "value": "Não"
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "label": "Empresa",
        "name": "empresa",
        "select": [
          {
            "type": "option",
            "name": "Agrichem",
            "value": "Agrichem",
            "fields": [
              {
                "label": "Filiais - Agrichem",
                "name": "filial_agrichem",
                "select": [
                  {"type": "option","name": "AGRICHEM DO BRASIL S.A","value": "AGRICHEM DO BRASIL S.A"},
                  {"type": "option","name": "Filial_0004_03.860.998/0004-35_SP","value": "Filial_0004_03.860.998/0004-35_SP"},
                  {"type": "option","name": "Matriz_0001_03.860.998/0001-92_SP","value": "Matriz_0001_03.860.998/0001-92_SP"}
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          // {
          //   "type": "option",
          //   "name": "Agrosema",
          //   "value": "Agrosema",
          //   "fields": [
          //     {
          //       "label": "Filiais - Agrosema",
          //       "name": "filial_agrosema",
          //       "select": [
          //         {
          //           "type": "option",
          //           "name": "Agrosema",
          //           "value": "Agrosema"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Alfenas-0031-31-Mg",
          //           "value": "Filial-Agrosema -Alfenas-0031-31-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Araxá-0029-17-Mg",
          //           "value": "Filial-Agrosema -Araxá-0029-17-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Capão Bonito-0005-40-Sp",
          //           "value": "Filial-Agrosema -Capão Bonito-0005-40-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Coromandel-0026-74-Mg",
          //           "value": "Filial-Agrosema -Coromandel-0026-74-Mg",
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Elias Fausto-0012-79-Sp",
          //           "value": "Filial-Agrosema -Elias Fausto-0012-79-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Ibiá-0018-64-Mg",
          //           "value": "Filial-Agrosema -Ibiá-0018-64-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Ibiá-0028-36-Mg",
          //           "value": "Filial-Agrosema -Ibiá-0028-36-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Indaiatuba-0011-98-Sp",
          //           "value": "Filial-Agrosema -Indaiatuba-0011-98-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Itapetininga-0008-92-Sp",
          //           "value": "Filial-Agrosema -Itapetininga-0008-92-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Ituiutaba-0032-12-Mg",
          //           "value": "Filial-Agrosema -Ituiutaba-0032-12-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Mogi-Guaçu-0007-01-Sp",
          //           "value": "Filial-Agrosema -Mogi-Guaçu-0007-01-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Patos De Minas-0015-11-Mg",
          //           "value": "Filial-Agrosema -Patos De Minas-0015-11-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Patrocínio-0027-55-Mg",
          //           "value": "Filial-Agrosema -Patrocínio-0027-55-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -São Gotardo-0030-50-Mg",
          //           "value": "Filial-Agrosema -São Gotardo-0030-50-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -São Miguel Arcanjo-0006-20-Sp",
          //           "value": "Filial-Agrosema -São Miguel Arcanjo-0006-20-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Taquarituba-0013-50-Sp",
          //           "value": "Filial-Agrosema -Taquarituba-0013-50-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Uberaba-0023-21-Mg",
          //           "value": "Filial-Agrosema -Uberaba-0023-21-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Uberaba-0024-02-Mg",
          //           "value": "Filial-Agrosema -Uberaba-0024-02-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Uberaba-0025-93-Mg",
          //           "value": "Filial-Agrosema -Uberaba-0025-93-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Uberlândia-0022-40-Mg",
          //           "value": "Filial-Agrosema -Uberlândia-0022-40-Mg"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Filial-Agrosema -Vargem Grande Do Sul-0016-00-Sp",
          //           "value": "Filial-Agrosema -Vargem Grande Do Sul-0016-00-Sp"
          //         },
          //         {
          //           "type": "option",
          //           "name": "Matriz-Agrosema -Elias Fausto-0001-16-Sp",
          //           "value": "Matriz-Agrosema -Elias Fausto-0001-16-Sp"
          //         }
          //       ],
          //       "type": "select",
          //       "validators": Validators.required
          //     }
          //   ]
          // },
          {
            "type": "option",
            "name": "Nutrien",
            "value": "Nutrien",
            "fields": [
              {
                "label": "Filiais - Nutrien",
                "name": "filial_nutrien",
                "select": [
                  {"type": "option","name": "FILIAL_7001_88.305.859/0004-00_SP","value": "FILIAL_7001_88.305.859/0004-00_SP"},
                  {"type": "option","name": "FILIAL_7002_88.305.859/0015-55_MG","value": "FILIAL_7002_88.305.859/0015-55_MG"},
                  {"type": "option","name": "FILIAL_7003_88.305.859/0020-12_GO","value": "FILIAL_7003_88.305.859/0020-12_GO"},
                  {"type": "option","name": "FILIAL_7004_88.305.859/0021-01_GO","value": "FILIAL_7004_88.305.859/0021-01_GO"},
                  {"type": "option","name": "FILIAL_7005_88.305.859/0014-74_SP","value": "FILIAL_7005_88.305.859/0014-74_SP"},
                  {"type": "option","name": "FILIAL_7006_88.305.859/0005-83_SP","value": "FILIAL_7006_88.305.859/0005-83_SP"},
                  {"type": "option","name": "FILIAL_7007_88.305.859/0018-06_SP","value": "FILIAL_7007_88.305.859/0018-06_SP"},
                  {"type": "option","name": "FILIAL_7008_88.305.859/0019-89_SP","value": "FILIAL_7008_88.305.859/0019-89_SP"},
                  {"type": "option","name": "FILIAL_7009_88.305.859/0026-08_SP","value": "FILIAL_7009_88.305.859/0026-08_SP"},
                  {"type": "option","name": "FILIAL_7010_88.305.859/0022-84_SP","value": "FILIAL_7010_88.305.859/0022-84_SP"},
                  {"type": "option","name": "FILIAL_7011_88.305.859/0056-23_SP","value": "FILIAL_7011_88.305.859/0056-23_SP"},
                  {"type": "option","name": "FILIAL_7012_88.305.859/0041-47_SP","value": "FILIAL_7012_88.305.859/0041-47_SP"},
                  {"type": "option","name": "FILIAL_7013_88.305.859/0039-22_SP","value": "FILIAL_7013_88.305.859/0039-22_SP"},
                  {"type": "option","name": "FILIAL_7014_88.305.859/0042-28_SP","value": "FILIAL_7014_88.305.859/0042-28_SP"},
                  {"type": "option","name": "FILIAL_7015_88.305.859/0025-27_MG","value": "FILIAL_7015_88.305.859/0025-27_MG"},
                  {"type": "option","name": "FILIAL_7016_88.305.859/0040-66_SP","value": "FILIAL_7016_88.305.859/0040-66_SP"},
                  {"type": "option","name": "FILIAL_7017_88.305.859/0051-19_SP","value": "FILIAL_7017_88.305.859/0051-19_SP"},
                  {"type": "option","name": "FILIAL_7018_88.305.859/0036-80_MG","value": "FILIAL_7018_88.305.859/0036-80_MG"},
                  {"type": "option","name": "FILIAL_7019_88.305.859/0052-08_SP","value": "FILIAL_7019_88.305.859/0052-08_SP"},
                  {"type": "option","name": "FILIAL_7020_88.305.859/0050-38_SP","value": "FILIAL_7020_88.305.859/0050-38_SP"},
                  {"type": "option","name": "FILIAL_7021_88.305.859/0062-71_SP","value": "FILIAL_7021_88.305.859/0062-71_SP"},
                  {"type": "option","name": "FILIAL_7022_88.305.859/0073-24_MS","value": "FILIAL_7022_88.305.859/0073-24_MS"},
                  {"type": "option","name": "FILIAL_7023_88.305.859/0074-05_MS","value": "FILIAL_7023_88.305.859/0074-05_MS"},
                  {"type": "option","name": "FILIAL_7024_88.305.859/0080-53_MS","value": "FILIAL_7024_88.305.859/0080-53_MS"},
                  {"type": "option","name": "FILIAL_7025_88.305.859/0072-43_MS","value": "FILIAL_7025_88.305.859/0072-43_MS"},
                  {"type": "option","name": "FILIAL_7026_88.305.859/0075-96_MS","value": "FILIAL_7026_88.305.859/0075-96_MS"},
                  {"type": "option","name": "FILIAL_7027_88.305.859/0079-10_MS","value": "FILIAL_7027_88.305.859/0079-10_MS"},
                  {"type": "option","name": "FILIAL_7028_88.305.859/0077-58_MS","value": "FILIAL_7028_88.305.859/0077-58_MS"},
                  {"type": "option","name": "FILIAL_7029_88.305.859/0081-34_MS","value": "FILIAL_7029_88.305.859/0081-34_MS"},
                  {"type": "option","name": "FILIAL_7030_88.305.859/0078-39_MS","value": "FILIAL_7030_88.305.859/0078-39_MS"},
                  {"type": "option","name": "FILIAL_7031_88.305.859/0076-77_MS","value": "FILIAL_7031_88.305.859/0076-77_MS"},
                  {"type": "option","name": "FILIAL_7032_88.305.859/0069-48_MG","value": "FILIAL_7032_88.305.859/0069-48_MG"},
                  {"type": "option","name": "FILIAL_7033_88.305.859/0070-81_MG","value": "FILIAL_7033_88.305.859/0070-81_MG"},
                  {"type": "option","name": "FILIAL_7034_88.305.859/0071-62_MG","value": "FILIAL_7034_88.305.859/0071-62_MG"},
                  {"type": "option","name": "FILIAL_7035_88.305.859/0067-86_MG","value": "FILIAL_7035_88.305.859/0067-86_MG"},
                  {"type": "option","name": "FILIAL_7036_88.305.859/0066-03_MG","value": "FILIAL_7036_88.305.859/0066-03_MG"},
                  {"type": "option","name": "FILIAL_7037_88.305.859/0065-14_MG","value": "FILIAL_7037_88.305.859/0065-14_MG"},
                  {"type": "option","name": "FILIAL_7039_88.305.859/0017-17_SP","value": "FILIAL_7039_88.305.859/0017-17_SP"},
                  {"type": "option","name": "FILIAL_7040_88.305.859/0027-99_MG","value": "FILIAL_7040_88.305.859/0027-99_MG"},
                  {"type": "option","name": "FILIAL_7041_88.305.859/0028-70_SP","value": "FILIAL_7041_88.305.859/0028-70_SP"},
                  {"type": "option","name": "FILIAL_7042_88.305.859/0037-60_MG","value": "FILIAL_7042_88.305.859/0037-60_MG"},
                  {"type": "option","name": "FILIAL_7043_88.305.859/0032-56_MG","value": "FILIAL_7043_88.305.859/0032-56_MG"},
                  {"type": "option","name": "FILIAL_7044_88.305.859/0054-61_MG","value": "FILIAL_7044_88.305.859/0054-61_MG"},
                  {"type": "option","name": "FILIAL_7045_88.305.859/0053-80_MG","value": "FILIAL_7045_88.305.859/0053-80_MG"},
                  {"type": "option","name": "FILIAL_7046_88.305.859/0033-37_MG","value": "FILIAL_7046_88.305.859/0033-37_MG"},
                  {"type": "option","name": "FILIAL_7047_88.305.859/0044-90_MG","value": "FILIAL_7047_88.305.859/0044-90_MG"},
                  {"type": "option","name": "FILIAL_7048_88.305.859/0029-50_SP","value": "FILIAL_7048_88.305.859/0029-50_SP"},
                  {"type": "option","name": "FILIAL_7049_88.305.859/0034-18_MG","value": "FILIAL_7049_88.305.859/0034-18_MG"},
                  {"type": "option","name": "FILIAL_7050_88.305.859/0045-70_MG","value": "FILIAL_7050_88.305.859/0045-70_MG"},
                  {"type": "option","name": "FILIAL_7051_88.305.859/0073-24_MS","value": "FILIAL_7051_88.305.859/0073-24_MS"},
                  {"type": "option","name": "FILIAL_7052_88.305.859/0082-15_MS","value": "FILIAL_7052_88.305.859/0082-15_MS"},
                  {"type": "option","name": "FILIAL_7078_88.305.859/0063-52_SP","value": "FILIAL_7078_88.305.859/0063-52_SP"},
                  {"type": "option","name": "FILIAL_7079_88.305.859/0031-75_MG","value": "FILIAL_7079_88.305.859/0031-75_MG"},
                  {"type": "option","name": "FILIAL_7080_88.305.859/0043-09_MG","value": "FILIAL_7080_88.305.859/0043-09_MG"},
                  {"type": "option","name": "FILIAL_7081_88.305.859/0035-07_MG","value": "FILIAL_7081_88.305.859/0035-07_MG"},
                  {"type": "option","name": "FILIAL_7082_88.305.859/0030-94_MG","value": "FILIAL_7082_88.305.859/0030-94_MG"},
                  {"type": "option","name": "FILIAL_7083_88.305.859/0038-41_SP","value": "FILIAL_7083_88.305.859/0038-41_SP"},
                  {"type": "option","name": "FILIAL_7084_88.305.859/0046-51_SP","value": "FILIAL_7084_88.305.859/0046-51_SP"},
                  {"type": "option","name": "FILIAL_7085_88.305.859/0047-32_SP","value": "FILIAL_7085_88.305.859/0047-32_SP"},
                  {"type": "option","name": "FILIAL_7086_88.305.859/0048-13_SP","value": "FILIAL_7086_88.305.859/0048-13_SP"},
                  {"type": "option","name": "FILIAL_7087_88.305.859/0049-02_SP","value": "FILIAL_7087_88.305.859/0049-02_SP"},
                  {"type": "option","name": "FILIAL_7088_88.305.859/0055-42_SP","value": "FILIAL_7088_88.305.859/0055-42_SP"},
                  {"type": "option","name": "FILIAL_7089_88.305.859/0024-46_SP","value": "FILIAL_7089_88.305.859/0024-46_SP"},
                  {"type": "option","name": "FILIAL_7090_88.305.859/0059-76_SP","value": "FILIAL_7090_88.305.859/0059-76_SP"},
                  {"type": "option","name": "FILIAL_7091_88.305.859/0057-04_SP","value": "FILIAL_7091_88.305.859/0057-04_SP"},
                  {"type": "option","name": "FILIAL_7092_88.305.859/0060-00_SP","value": "FILIAL_7092_88.305.859/0060-00_SP"},
                  {"type": "option","name": "FILIAL_7093_88.305.859/0061-90_GO","value": "FILIAL_7093_88.305.859/0061-90_GO"},
                  {"type": "option","name": "FILIAL_7094_88.305.859/0058-95_GO","value": "FILIAL_7094_88.305.859/0058-95_GO"},
                  {"type": "option","name": "FILIAL_7095_88.305.859/0064-33_SP","value": "FILIAL_7095_88.305.859/0064-33_SP"},
                  {"type": "option","name": "MATRIZ_7000_88.305.859/0001-50_SP","value": "MATRIZ_7000_88.305.859/0001-50_SP"},
                  {"type": "option","name": "NUTRIEN SOLUÇÕES AGRÍCOLAS","value": "NUTRIEN SOLUÇÕES AGRÍCOLAS"}
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Tec Agro-FRC",
            "value": "Tec Agro-FRC",
            "fields": [
              {
                "label": "Filiais - Tec Agro-FRC",
                "name": "filiais_tec_agro_frc",
                "select": [
                  {"type": "option","name": "Filial_02_36.854.495/0002-44_GO","value": "Filial_02_36.854.495/0002-44_GO"},
                  {"type": "option","name": "Filial_04_36.854.495/0003-25_GO","value": "Filial_04_36.854.495/0003-25_GO"},
                  {"type": "option","name": "Filial_05_36.854.495/0004-06_GO","value": "Filial_05_36.854.495/0004-06_GO"},
                  {"type": "option","name": "Filial_06_36.854.495/0005-97_GO","value": "Filial_06_36.854.495/0005-97_GO"},
                  {"type": "option","name": "Filial_08_36.854.495/0006-78_GO","value": "Filial_08_36.854.495/0006-78_GO"},
                  {"type": "option","name": "Filial_07_36.854.495/0007-59_GO","value": "Filial_07_36.854.495/0007-59_GO"},
                  {"type": "option","name": "Filial_09_36.854.495/0008-30_GO","value": "Filial_09_36.854.495/0008-30_GO"},
                  {"type": "option","name": "Filial_13_36.854.495/0009-10_SP","value": "Filial_13_36.854.495/0009-10_SP"},
                  {"type": "option","name": "Filial_14_36.854.495/0010-54_MG","value": "Filial_14_36.854.495/0010-54_MG"},
                  {"type": "option","name": "Filial_18_36.854.495/0011-35_GO","value": "Filial_18_36.854.495/0011-35_GO"},
                  {"type": "option","name": "Filial_17_36.854.495/0012-16_GO","value": "Filial_17_36.854.495/0012-16_GO"},
                  {"type": "option","name": "Filial_16_36.854.495/0013-05_TO","value": "Filial_16_36.854.495/0013-05_TO"},
                  {"type": "option","name": "Filial_15_36.854.495/0014-88_TO","value": "Filial_15_36.854.495/0014-88_TO"},
                  {"type": "option","name": "Filial_19_36.854.495/0015-69_GO","value": "Filial_19_36.854.495/0015-69_GO"},
                  {"type": "option","name": "Filial_20_36.854.495/0016-40_GO","value": "Filial_20_36.854.495/0016-40_GO"},
                  {"type": "option","name": "Filial_22_36.854.495/0017-20_GO","value": "Filial_22_36.854.495/0017-20_GO"},
                  {"type": "option","name": "Filial_23_36.854.495/0018-01_TO","value": "Filial_23_36.854.495/0018-01_TO"},
                  {"type": "option","name": "Filial_24_36.854.495/0019-92_TO","value": "Filial_24_36.854.495/0019-92_TO"},
                  {"type": "option","name": "Filial_25_36.854.495/0020-26_TO","value": "Filial_25_36.854.495/0020-26_TO"},
                  {"type": "option","name": "Filial_26_36.854.495/0021-07_GO","value": "Filial_26_36.854.495/0021-07_GO"},
                  {"type": "option","name": "Filial_33_36.854.495/0022-98_GO","value": "Filial_33_36.854.495/0022-98_GO"},
                  {"type": "option","name": "Filial_27_36.854.495/0023-79_GO","value": "Filial_27_36.854.495/0023-79_GO"},
                  {"type": "option","name": "Filial_28_36.854.495/0024-50_GO","value": "Filial_28_36.854.495/0024-50_GO"},
                  {"type": "option","name": "Filial_32_36.854.495/0025-30_GO","value": "Filial_32_36.854.495/0025-30_GO"},
                  {"type": "option","name": "Filial_29_36.854.495/0026-11_GO","value": "Filial_29_36.854.495/0026-11_GO"},
                  {"type": "option","name": "Filial_41_36.854.495/0027-00_GO","value": "Filial_41_36.854.495/0027-00_GO"},
                  {"type": "option","name": "Filial_40_36.854.495/0028-83_GO","value": "Filial_40_36.854.495/0028-83_GO"},
                  {"type": "option","name": "Filial_37_36.854.495/0029-64_GO","value": "Filial_37_36.854.495/0029-64_GO"},
                  {"type": "option","name": "Filial_35_36.854.495/0030-06_GO","value": "Filial_35_36.854.495/0030-06_GO"},
                  {"type": "option","name": "Filial_38_36.854.495/0032-60_TO","value": "Filial_38_36.854.495/0032-60_TO"},
                  {"type": "option","name": "Filial_39_36.854.495/0033-40_TO","value": "Filial_39_36.854.495/0033-40_TO"},
                  {"type": "option","name": "Filial_34_36.854.495/0034-21_GO","value": "Filial_34_36.854.495/0034-21_GO"},
                  {"type": "option","name": "Filial_42_36.854.495/0035-02_GO","value": "Filial_42_36.854.495/0035-02_GO"},
                  {"type": "option","name": "Matriz_01_36.854.495/0001-63_GO","value": "Matriz_01_36.854.495/0001-63_GO"},
                  {"type": "option","name": "Tec Agro-FRC","value": "Tec Agro-FRC"}
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Tec Agro-SGL",
            "value": "Tec Agro-SGL",
            "fields": [
              {
                "label": "Filiais - Tec Agro-SGL",
                "name": "filiais_tec_agro_sgl",
                "select": [
                  {"type": "option","name": "Filial_0302_03.482.332/0002-29_GO","value": "Filial_0302_03.482.332/0002-29_GO"},
                  {"type": "option","name": "Filial_0303_03.482.332/0003-00_GO","value": "Filial_0303_03.482.332/0003-00_GO"},
                  {"type": "option","name": "Filial_0304_03.482.332/0004-90_TO","value": "Filial_0304_03.482.332/0004-90_TO"},
                  {"type": "option","name": "Filial_0305_03.482.332/0005-71_GO","value": "Filial_0305_03.482.332/0005-71_GO"},
                  {"type": "option","name": "Filial_0306_03.482.332/0006-52_MG","value": "Filial_0306_03.482.332/0006-52_MG"},
                  {"type": "option","name": "Filial_0307_03.482.332/0007-33_MS","value": "Filial_0307_03.482.332/0007-33_MS"},
                  {"type": "option","name": "Filial_0308_03.482.332/0008-14_PR","value": "Filial_0308_03.482.332/0008-14_PR"},
                  {"type": "option","name": "Matriz_0301_03.482.332/0001-48_GO","value": "Matriz_0301_03.482.332/0001-48_GO"},
                  {"type": "option","name": "Tec Agro-SGL","value": "Tec Agro-SGL"}
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          }
        ],
        "type": "select",
        "validators": Validators.required
      },
      {
        "type": "radioHorizontal",
        "label": "Seleção da retificação",
        "name": "sele_o",
        "validators": Validators.required,
        "select": [
          {
            type: "option",
            name: "Apuração",
            value: "Apuração",
            "fields": [
              {
                "label": "Apuração",
                "name": "obriga_o_acess_ria",
                "select": [
                  {
                    "type": "option",
                    "name": "Apuração anual",
                    "value": "Apuração anual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração CSRF -  (PCC)",
                    "value": "Apuração CSRF -  (PCC)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS sobre frete",
                    "value": "Apuração de ICMS sobre frete"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS, IPI e Difal",
                    "value": "Apuração de ICMS, IPI e Difal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de imposto de renda",
                    "value": "Apuração de imposto de renda"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS",
                    "value": "Apuração de PIS e a COFINS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS  1° parte",
                    "value": "Apuração de PIS e a COFINS  1° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e COFINS 2° parte",
                    "value": "Apuração de PIS e COFINS 2° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços prestados (ISS)",
                    "value": "Apuração de serviços prestados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços tomados (ISS)",
                    "value": "Apuração de serviços tomados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração diferencial de alíquotas (Difal)",
                    "value": "Apuração diferencial de alíquotas (Difal)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração do estorno de crédito",
                    "value": "Apuração do estorno de crédito"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Estadual",
                    "value": "Apuração dos impostos Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Federal",
                    "value": "Apuração dos impostos Federal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Municipais",
                    "value": "Apuração dos impostos Municipais"
                  },
                  {
                    "type": "option",
                    "name": "Apuração Funrural",
                    "value": "Apuração Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração INSS",
                    "value": "Apuração INSS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRPJ e CSLL",
                    "value": "Apuração IRPJ e CSLL"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF",
                    "value": "Apuração IRRF"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF,CSRF, INSS e Funrural",
                    "value": "Apuração IRRF,CSRF, INSS e Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração semestral",
                    "value": "Apuração semestral"
                  },
                  {
                    "type": "option",
                    "name": "Apuração trimestral",
                    "value": "Apuração trimestral"
                  },
                  {
                    "type": "option",
                    "name": "Balancete trimestral Armazém geral",
                    "value": "Balancete trimestral Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "Balanço anual Armazém geral",
                    "value": "Balanço anual Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  },
                  {
                    "type": "option",
                    "name": "DCOMP- Compensação - Mensal  - Pagamento indevido",
                    "value": "DCOMP- Compensação - Mensal  - Pagamento indevido"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            type: "option",
            name: "Apuração-Obrigação acessória",
            value: "Apuração-Obrigação acessória",
            "fields": [
              {
                "label": "Apuração",
                "name": "obriga_o_acess_ria",
                "select": [
                  {
                    "type": "option",
                    "name": "Apuração anual",
                    "value": "Apuração anual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração CSRF - (PCC)",
                    "value": "Apuração CSRF - (PCC)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS sobre frete",
                    "value": "Apuração de ICMS sobre frete"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de ICMS, IPI e Difal",
                    "value": "Apuração de ICMS, IPI e Difal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de imposto de renda",
                    "value": "Apuração de imposto de renda"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS",
                    "value": "Apuração de PIS e a COFINS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e a COFINS  1° parte",
                    "value": "Apuração de PIS e a COFINS  1° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de PIS e COFINS 2° parte",
                    "value": "Apuração de PIS e COFINS 2° parte"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços prestados (ISS)",
                    "value": "Apuração de serviços prestados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração de serviços tomados (ISS)",
                    "value": "Apuração de serviços tomados (ISS)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração diferencial de alíquotas (Difal)",
                    "value": "Apuração diferencial de alíquotas (Difal)"
                  },
                  {
                    "type": "option",
                    "name": "Apuração do estorno de crédito",
                    "value": "Apuração do estorno de crédito"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Estadual",
                    "value": "Apuração dos impostos Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Federal",
                    "value": "Apuração dos impostos Federal"
                  },
                  {
                    "type": "option",
                    "name": "Apuração dos impostos Municipais",
                    "value": "Apuração dos impostos Municipais"
                  },
                  {
                    "type": "option",
                    "name": "Apuração Funrural",
                    "value": "Apuração Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração INSS",
                    "value": "Apuração INSS"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRPJ e CSLL",
                    "value": "Apuração IRPJ e CSLL"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF",
                    "value": "Apuração IRRF"
                  },
                  {
                    "type": "option",
                    "name": "Apuração IRRF,CSRF, INSS e Funrural",
                    "value": "Apuração IRRF,CSRF, INSS e Funrural"
                  },
                  {
                    "type": "option",
                    "name": "Apuração semestral",
                    "value": "Apuração semestral"
                  },
                  {
                    "type": "option",
                    "name": "Apuração trimestral",
                    "value": "Apuração trimestral"
                  },
                  {
                    "type": "option",
                    "name": "Balancete trimestral Armazém geral",
                    "value": "Balancete trimestral Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "Balanço anual Armazém geral",
                    "value": "Balanço anual Armazém geral"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  },
                  {
                    "type": "option",
                    "name": "DCOMP- Compensação - Mensal - Pagamento indevido",
                    "value": "DCOMP- Compensação - Mensal - Pagamento indevido"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)",
                    "value": "PER/DCOMP - Restituição - PIS e COFINS (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP - Restituição- IPI (Trimestral)",
                    "value": "PER/DCOMP - Restituição- IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - IPI (Trimestral)",
                    "value": "PER/DCOMP -Compensação - IPI (Trimestral)"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)",
                    "value": "PER/DCOMP -Compensação - PIS e COFINS (Mensal)"
                  },
                  {
                    "type": "option",
                    "name": "Retificação de DARF - Redarf",
                    "value": "Retificação de DARF - Redarf"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              },
              {
                "label": "Obrigação acessória",
                "name": "obriga_o_acess_ria_2",
                "select": [
                  {
                    "type": "option",
                    "name": "DAMEF - Declarção Anual do Movimento Econômico Fiscal",
                    "value": "DAMEF - Declarção Anual do Movimento Econômico Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "DAPI-Declaração de Apuração e Informação do ICMS",
                    "value": "DAPI-Declaração de Apuração e Informação do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "DCTF - Declaração de Débitos Tributários Federais",
                    "value": "DCTF - Declaração de Débitos Tributários Federais"
                  },
                  {
                    "type": "option",
                    "name": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários",
                    "value": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários"
                  },
                  {
                    "type": "option",
                    "name": "DES - Declaração Eletrônica de Serviços",
                    "value": "DES - Declaração Eletrônica de Serviços"
                  },
                  {
                    "type": "option",
                    "name": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre",
                    "value": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre"
                  },
                  {
                    "type": "option",
                    "name": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida",
                    "value": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM - Declaração para o Índice de Participação dos Municípios",
                    "value": "DIPAM - Declaração para o Índice de Participação dos Municípios"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM-A dos produtores agropecuários paulista",
                    "value": "DIPAM-A dos produtores agropecuários paulista"
                  },
                  {
                    "type": "option",
                    "name": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica",
                    "value": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica"
                  },
                  {
                    "type": "option",
                    "name": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte",
                    "value": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte"
                  },
                  {
                    "type": "option",
                    "name": "DMS- Declaração mensal de serviços- GO",
                    "value": "DMS- Declaração mensal de serviços- GO"
                  },
                  {
                    "type": "option",
                    "name": "DOT - Declaração de Operações Tributáveis -ES",
                    "value": "DOT - Declaração de Operações Tributáveis -ES"
                  },
                  {
                    "type": "option",
                    "name": "ECD- Escrituração Contábil Digital",
                    "value": "ECD- Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "ECF – Escrituração Contábil Fiscal -ICMS-IPI",
                    "value": "ECF – Escrituração Contábil Fiscal -ICMS-IPI"
                  },
                  {
                    "type": "option",
                    "name": "EFD Contribuições",
                    "value": "EFD Contribuições"
                  },
                  {
                    "type": "option",
                    "name": "EFD ICMS/IPI – Escrituração Contábil Digital",
                    "value": "EFD ICMS/IPI – Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções",
                    "value": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções"
                  },
                  {
                    "type": "option",
                    "name": "E-Social",
                    "value": "E-Social"
                  },
                  {
                    "type": "option",
                    "name": "GIA – Guia de Informação e Apuração do ICMS",
                    "value": "GIA – Guia de Informação e Apuração do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "GIA — substituição tributária",
                    "value": "GIA — substituição tributária"
                  },
                  {
                    "type": "option",
                    "name": "GIAM-Guia De Informação E Apuração Mensal Do ICMS",
                    "value": "GIAM-Guia De Informação E Apuração Mensal Do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "Livro de Apuração do Lucro Real (Lalur)",
                    "value": "Livro de Apuração do Lucro Real (Lalur)"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Estadual",
                    "value": "Obrigação acessoria Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Federal",
                    "value": "Obrigação acessoria Federal"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Municipal",
                    "value": "Obrigação acessoria Municipal"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso",
                    "value": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso"
                  },
                  {
                    "type": "option",
                    "name": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais",
                    "value": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco H",
                    "value": "SPED fiscal – Bloco H"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco K",
                    "value": "SPED fiscal – Bloco K"
                  },
                  {
                    "type": "option",
                    "name": "VAF- Valor Adicionado Fiscal",
                    "value": "VAF- Valor Adicionado Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "Outras obrigações acessórias que não estão discriminadas na lista",
                    "value": "Outras obrigações acessórias que não estão discriminadas na lista"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              },
              {
                "label": "Competência da obrigação acessória e apuração retificada",
                "name": "compet_ncia",
                "select": [
                  {
                    "type": "option",
                    "name": "Janeiro ",
                    "value": "Janeiro "
                  },
                  {
                    "type": "option",
                    "name": "Fevereiro",
                    "value": "Fevereiro"
                  },
                  {
                    "type": "option",
                    "name": "Março",
                    "value": "Março"
                  },
                  {
                    "type": "option",
                    "name": "Abril",
                    "value": "Abril"
                  },
                  {
                    "type": "option",
                    "name": "Maio",
                    "value": "Maio"
                  },
                  {
                    "type": "option",
                    "name": "Junho",
                    "value": "Junho"
                  },
                  {
                    "type": "option",
                    "name": "Julho",
                    "value": "Julho"
                  },
                  {
                    "type": "option",
                    "name": "Agosto",
                    "value": "Agosto"
                  },
                  {
                    "type": "option",
                    "name": "Setembro",
                    "value": "Setembro"
                  },
                  {
                    "type": "option",
                    "name": "Outubro",
                    "value": "Outubro"
                  },
                  {
                    "type": "option",
                    "name": "Novembro",
                    "value": "Novembro"
                  },
                  {
                    "type": "option",
                    "name": "Dezembro",
                    "value": "Dezembro"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          },
          {
            type: "option",
            name: "Obrigação acessória",
            value: "Obrigação acessória",
            "fields": [
              {
                "label": "Obrigação acessória",
                "name": "obriga_o_acess_ria_2",
                "select": [
                  {
                    "type": "option",
                    "name": "DAMEF - Declarção Anual do Movimento Econômico Fiscal",
                    "value": "DAMEF - Declarção Anual do Movimento Econômico Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "DAPI-Declaração de Apuração e Informação do ICMS",
                    "value": "DAPI-Declaração de Apuração e Informação do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "DCTF – Declaração de Débitos Tributários Federais",
                    "value": "DCTF – Declaração de Débitos Tributários Federais"
                  },
                  {
                    "type": "option",
                    "name": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários",
                    "value": "DCTFWeb - Declaração de Débitos e Créditos Tributários Federais Previdenciários"
                  },
                  {
                    "type": "option",
                    "name": "DES – Declaração Eletrônica de Serviços",
                    "value": "DES – Declaração Eletrônica de Serviços"
                  },
                  {
                    "type": "option",
                    "name": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre",
                    "value": "DEVEC - Declaração do Valor de Aquisição da Energia Elétrica em Ambiente de Contratação livre"
                  },
                  {
                    "type": "option",
                    "name": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida",
                    "value": "DIME - Declaração de ICMS e Movimentos Econômicos conhecida"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM - Declaração para o Índice de Participação dos Municípios",
                    "value": "DIPAM - Declaração para o Índice de Participação dos Municípios"
                  },
                  {
                    "type": "option",
                    "name": "DIPAM-A dos produtores agropecuários paulista",
                    "value": "DIPAM-A dos produtores agropecuários paulista"
                  },
                  {
                    "type": "option",
                    "name": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica",
                    "value": "DIPJ- Declaração De Imposto De Renda Pessoa Jurídica"
                  },
                  {
                    "type": "option",
                    "name": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte",
                    "value": "DIRF – Declaração do Imposto sobre a Renda Retido na Fonte"
                  },
                  {
                    "type": "option",
                    "name": "DMS- Declaração mensal de serviços- GO",
                    "value": "DMS- Declaração mensal de serviços- GO"
                  },
                  {
                    "type": "option",
                    "name": "DOT - Declaração de Operações Tributáveis -ES",
                    "value": "DOT - Declaração de Operações Tributáveis -ES"
                  },
                  {
                    "type": "option",
                    "name": "ECD- Escrituração Contábil Digital",
                    "value": "ECD- Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "ECF – Escrituração Contábil Fiscal -ICMS-IPI",
                    "value": "ECF – Escrituração Contábil Fiscal -ICMS-IPI"
                  },
                  {
                    "type": "option",
                    "name": "EFD Contribuições",
                    "value": "EFD Contribuições"
                  },
                  {
                    "type": "option",
                    "name": "EFD ICMS/IPI – Escrituração Contábil Digital",
                    "value": "EFD ICMS/IPI – Escrituração Contábil Digital"
                  },
                  {
                    "type": "option",
                    "name": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções",
                    "value": "EFD-Reinf - Escrituração Fiscal Digital  de Retenções"
                  },
                  {
                    "type": "option",
                    "name": "E-Social",
                    "value": "E-Social"
                  },
                  {
                    "type": "option",
                    "name": "GIA – Guia de Informação e Apuração do ICMS",
                    "value": "GIA – Guia de Informação e Apuração do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "GIA — substituição tributária",
                    "value": "GIA — substituição tributária"
                  },
                  {
                    "type": "option",
                    "name": "GIAM-Guia De Informação E Apuração Mensal Do ICMS",
                    "value": "GIAM-Guia De Informação E Apuração Mensal Do ICMS"
                  },
                  {
                    "type": "option",
                    "name": "Livro de Apuração do Lucro Real (Lalur)",
                    "value": "Livro de Apuração do Lucro Real (Lalur)"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Estadual",
                    "value": "Obrigação acessoria Estadual"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Federal",
                    "value": "Obrigação acessoria Federal"
                  },
                  {
                    "type": "option",
                    "name": "Obrigação acessoria Municipal",
                    "value": "Obrigação acessoria Municipal"
                  },
                  {
                    "type": "option",
                    "name": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso",
                    "value": "PER/DCOMP- Pedido de Restituição, Ressarcimento ou Reembolso"
                  },
                  {
                    "type": "option",
                    "name": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais",
                    "value": "SINTEGRA – Sistema Integrado de Informações sobre Operações Interestaduais"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco H",
                    "value": "SPED fiscal – Bloco H"
                  },
                  {
                    "type": "option",
                    "name": "SPED fiscal – Bloco K",
                    "value": "SPED fiscal – Bloco K"
                  },
                  {
                    "type": "option",
                    "name": "VAF- Valor Adicionado Fiscal",
                    "value": "VAF- Valor Adicionado Fiscal"
                  },
                  {
                    "type": "option",
                    "name": "Outras obrigações acessórias que não estão discriminadas na lista",
                    "value": "Outras obrigações acessórias que não estão discriminadas na lista"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          }
        ]
      },
      {
        "label": "Motivos da retificação",
        "name": "diverg_ncia_ocorrida",
        "type": "textArea",
        "validators": Validators.required
      },
      {
        "label": "Prazo para NBS retificar",
        "name": "prazo_para_nbs_retificar",
        "type": "date",
        "validators": Validators.required
      },
      {
        "label": "Notificações (anexar)",
        "name": "notifica_es_anexar",
        "type": "attachment",

      },
      {
        "label": "Cópia da obrigação acessoria",
        "name": "c_pia_de_obriga_es_acess_rias",
        "type": "attachment",
        "validators": Validators.required
      },
      {
        "label": "Link",
        "name": "link",
        "type": "textArea"
      },
      {
        "label": "Nome do Solicitante",
        "name": "nome_do_solicitante",
        "type": "text",
        "validators": Validators.required
      },
      {
        "label": "E-mail do Solicitante",
        "name": "e_mail_do_solicitante",
        "type": "email",
        "validators": Validators.required
      },
      {
        "label": "E-mail 2",
        "name": "e_mail_2",
        "type": "email"
      },
      {
        "label": "E-mail 3",
        "name": "e_mail_3",
        "type": "email"
      },
      {
        "label": "E-mail 4",
        "name": "e_mail_4",
        "type": "email"
      },
      {
        "label": "Analista responsável pela retificação-NBS",
        "name": "analista_reposavel_pela_retifica_o",
        "type": "text",
        "validators": Validators.required
      },
      {
        "label": "E-mail fiscal",
        "name": "e_mail_fiscal",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "agrosema.fiscal",
            "value": "agrosema.fiscal",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          },
          {
            "type": "option",
            "name": "agrichem.fiscal",
            "value": "agrichem.fiscal",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          },
          {
            "type": "option",
            "name": "nutrien.fiscal",
            "value": "nutrien.fiscal",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          },
          {
            "type": "option",
            "name": "tecagro.fiscal",
            "value": "tecagro.fiscal",
            "fields": [
              {
                "label": "E-mail NBS 2",
                "name": "e_mail_nbs_2",
                "type": "email"
              }
            ]
          }
        ],
        "type": "select"
      },

      {
        "label": "E-mail NBS 3",
        "name": "e_mail_nbs_3",
        "type": "email"
      },
      {
        "label": "Observação",
        "name": "observa_o",
        "type": "textArea"
      },
      {
        "type": "buttonSubmit",
        "name": "Enviar"
      }
    ]
  }
}





