export const environment = {
    "acessar_ipe": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-per-ipe-lanc",
    "cancelar_desligamento_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-solicitar-canc-demissao",
    "controle_de_versao": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-historico-versao-acesso",
    "apiIoga":'https://api.tfo.genai.dev.accenture.com/inn-ctx-gcpfn-genai-ioga-dev',
    "dev": true,
    "digibee_key": "",
    "email_versao": "dev2@versao.com",
    "gear_jwt_pass": "",
    "generate_card_anexo_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-generate-pipefy-cards-anexos",
    "generate_card_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-generate-pipefy-card",
    "generate_card_gcp": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-card",
    "generate_pipe_url_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-generate-pipefy-url",
    "generate_pipe_url_gcp": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-url",
    "gerar_jwt": "https://api.nutrien.nbs.dev.accenture.com/nutrien-gcpfn-portal-nbs-generate-jwt-token",
    "gerar_jwt_user": "nutrien-portal-nbs-sa-user-hml",
    "get_pipe_url": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-url",
    "get_secrets": "https://api.nutrien.nbs.dev.accenture.com/nutrien-gcpfn-authenticate-portal",
    "get_secrets_email": "accenture",
    "get_secrets_pass": "",
    "get_secrets_user": "nutrien-portal-nbs-sa-user-hml",
    "graph_groups_microsoft": "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true",
    "graph_microsoft": "https://graph.microsoft.com/v1.0/me",
    "homolog": false,
    "iogaKey": "",
    "iogaProject":"",
    "informe_rendimentos_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-informe-rendimento",
    "informe_tabelas_masterdata": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-tabelas-masterdata",
    "localhost": false,
    "most_qi_ocr": "https://mostqiapi.com/process-image/content-extraction",
    "most_qi_ocr_authorization": "",
    "ped_venda_fat_produtos_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-carga-lista-excel-materiais",
    "pipefy_key": "",
    "pipefy_queries": "https://app.pipefy.com/queries",
    "pipefy_queries_anexo": "https://app.pipefy.com/queries",
    "pipefy_queries_anexo_cookie": "__cfruid=659827f285967f7b7d9c656b82225a8766a75d78-1680286746",
    "pipefy_queries_autorization": "",
    "production": false,
    "recover_status_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-email-recover-status",
    "recover_status_gcp": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-email-recover-status",
    "reembolso_digibee": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-envio-email-reembolso",
    "sala_de_suporte": "https://app.pipefy.com/public/form/i09bm1sh",
    "SSONutrienauthority": "",
    "SSONutrienclientId": "",
    "SSONutrienpostLogoutRedirectUri": "",
    "SSONutrienredirectUri": "",
    "SSOAccentureauthority": "https://login.microsoftonline.com/a5ee2b85-0565-454f-93e2-6c7c6c616f7d",
    "SSOAccentureclientId": "6c9ab434-de0b-4e6c-961e-29ff852f8449",
    "SSOAccenturepostLogoutRedirectUri": "https://nbs.dev.accenture.com/",
    "SSOAccentureredirectUri": "https://nbs.dev.accenture.com",
    "tabela_masterdata": "https://api.godigibee.io/pipeline/accenture/v1/api-nutrien-gateway-portal-nbs-tabelas-masterdata",
    "verificar_atualizacao_minutos": 5,
    "versao": "13.1.213",
    "versaoPopup": "13.1.213",
    "versaoData": "03/01/2024",
    "versaoHora": "11:11",
    "zenviaChat": "",
    "userNameMock": "",
    "userEmailMock": ""
  };
