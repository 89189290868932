export const emailNbs = [
    {
      nome:''
    },
    {
      nome:'agrichem.fiscal'
    },
    {
      nome:'agrosema.fiscal'
    },
    {
      nome:'nutrien.fiscal'
    },
    {
      nome:'tecagro.fiscal'
    }
  ]
