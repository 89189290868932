export const naturezaDoLancamento =[
    {
        tipo:""
    },
    {
        tipo:"Ajuste de inventário"
    },
    {
        tipo:"Apropriação de despesas antecipadas/encargos transcorrer"

    },
    {
        tipo:"Ativo Imobilizado"
    },
    {
        tipo:"Atualização Selic",
    },
    {
        tipo:"Compensação de impostos"
    },
    {
        tipo:"Cut Off"
    },
    {
        tipo:"Derivativos - MtM"
    },
    {
        tipo:"Desconto de Pontualidade"
    },
    {
        tipo:"Empréstimos (incluindo apropriação de juros)"
    },
    {
        tipo:"Estorno de ICMS"
    },
    {
        tipo:"Erro Operacional"
    },
    {
        tipo:"Folha de pagamento"
    },
    {
        tipo:"Folha de Pagamento"
    },
    {
      tipo:"IFRS 16"
    },
    {
        tipo:"IRPJ/CSLL diferido"
    },
    {
        tipo:"Lançamento de AVJ"
    },
    {
        tipo:"Lançamento Subvenção para investimentos"
    },
    {
        tipo:"Outros (abrir campo adicional para explicar a natureza)"
    },
    {
        tipo:"PIS/COFINS sobre receitas financeiras"
    },
    {
        tipo:"Provisão Ajuste a valor presente de Fornecedores"
    },
    {
        tipo:"Provisão Auditoria"
    },
    {
        tipo:"Provisão Bônus Colaboradores"
    },
    {
        tipo:"Provisão de armazenagem"
    },
    {
        tipo:"Provisão de guias - ICMS/PIS/COFINS/IRPJ/CSLL/ISSQN/IRRF/FGTS/INSS/FUNRURAL/SENAR"
    },
    {
        tipo:"Provisão de NFs não lançadas"
    },
    {
        tipo:"Provisão de Royalties / Taxa de Tecnologia"
    },
    {
        tipo:"Provisão de seguros/consórcios/aluguéis a pagar"
    },
    {
        tipo:"Provisão estoque obsoleto"
    },
    {
        tipo:"Provisão Outros (abrir campo adicional para explicar a natureza)"
    },
    {
        tipo:"Provisão para perda de crédito esperada (PCLD)"
    },
    {
        tipo:"Provisão Rebates"
    },
    {
        tipo:"Reclassificação Intercompany"
    },
    {
        tipo:"Reclassificações contábeis (Centro de Custo/AFE)"
    },
    {
        tipo:"Reclassificações contábeis (entre contas contábeis)"
    }
]
